@import '../../../styles/customMediaQueries.css';

.root {
  composes: marketplaceModalFormRootStyles from global;

  justify-content: flex-start;
  max-width: 476px;

  @media (--viewportMedium) {
    justify-content: space-between;
  }
}

.content {
  composes: marketplaceModalBaseStyles from global;
  border-bottom: unset;
}

.header {
  & h3 {
    margin-bottom: 8px;
    font-size: 18px;
    color: var(--matterColorDark);
  }
  & p {
    margin-top: 0;
    font-size: 14px;
  }
}

.benefitsWrapper {
  display: flex;
  flex-direction: column;
  & .benefit {
    display: flex;
    align-items: flex-start;
    margin: 24px 0;
    & h3 {
      margin: 0;
      font-size: 18px;
      color: var(--matterColorDark);
    }
    & ul {
      margin: 8px 0 0 24px;
      list-style: disc;
      & li {
        font-size: 14px;
        line-height: 28px;
      }
    }
  }
}

.details {
  /* font-style: italic; */
}

.icon {
  margin-right: 8px;
}

.footer {
  & p {
    font-size: 14px;
    line-height: 28px;
    font-style: italic;
  }
}

.horizontal {
  @media (--viewportMedium) {
    max-width: 90% !important;
    margin: 0 auto;
    background: transparent;
    & .header {
      & h3 {
        margin: 0;
      }
      & p {
        margin: 0;
      }
    }
    & .content {
      background: transparent;
      margin-top: 0;
      padding-top: 0px;
      text-align: center;
    }

    & .benefitsWrapper {
      display: flex;
      flex-direction: row;
      gap: 48px;
      & .benefit {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
        .icon {
          margin-right: 0px;
          margin-bottom: 24px;
        }
        & h3 {
          margin-bottom: 24px;
          /* white-space: nowrap; */
        }
        & ul {
          & li {
            text-align: left;
          }
        }
      }
    }
    & .footer {
      & p {
        max-width: 80%;
        margin: 24px auto;
      }
    }
  }
}
